@import 'libs/styles/src/common/common';

@layer page {
  .hint {
    font-size: 12px;
    color: #9d9faa;

    a {
      color: $blue;

      &:hover {
        color: $dark-blue-text;
      }
    }
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6rem 0.5rem 0.5rem;
    width: 100%;

    &--tnc {
      padding-top: 0.5rem;
      font-size: 0.8em;
      align-items: flex-start;
    }
  }

  .auth-switch-button {
    margin: 0 auto;
    display: block;
    letter-spacing: 0;
  }

  .claim-profile-link {
    margin: 0 auto;
    display: block;
    letter-spacing: 0;
    margin-top: 10px;
  }
}
