@import 'libs/styles/src/common/common';

@layer component {
  .error-message {
    color: $blue;
    margin-bottom: 1rem;
    background-color: $highlight-light-blue;
    padding: 1rem;
    font-size: 18px;
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;

    h4 {
      font-size: 1em;
      text-align: center;

      svg {
        margin-top: -4px;
      }

      img {
        margin-top: -4px;
      }
    }
  }
}
