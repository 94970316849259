@import 'libs/styles/src/common/common';

@layer shared-component {
  .basic-page-container {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-bottom: 1rem;

    @include media-breakpoint-down(xs) {
      margin: 0 -15px;
      border-radius: 0;
    }

    hr {
      height: 1px;
      border: none;
      background-color: $hr-color;
    }

    &.background {
      box-shadow: $big-shadow;
      background-color: #fff;
      border-top: 10px solid $blue;
    }

    &.no-border {
      border-top: unset;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 28px;
    vertical-align: middle;
    background-color: $light-grey-blue;
    border-radius: 20px 20px 0 0;
    padding: 0 0.5rem;
    border-bottom: 2px solid #ebebeb;

    @include media-breakpoint-down(md) {
      button {
        margin: 0.75rem 0;
      }
    }

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      border-radius: 0;
    }

    & > * {
      padding: 0.375rem 1.375rem;
      margin: 1rem 0;

      @include media-breakpoint-down(xs) {
        padding: 0.375rem;
      }
    }

    &.no-border {
      border-bottom: unset;
    }
  }

  .hint {
    .what {
      margin: 0;
    }
  }

  .footer {
    display: block;
    padding: 1rem 3.25rem;
    border-top: 2px solid $light-gray;
    text-align: center;

    @include media-breakpoint-down(md) {
      padding: 1rem 1.625rem;
    }

    &.no-border {
      border-top: unset;
    }
  }

  .icon {
    margin-right: 1.5rem;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-right: 0.75rem;
    }
  }

  .icon-right {
    margin-left: 1.5rem;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-left: 0.75rem;
    }
  }

  .title__wrapper {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 2px;
    color: $dark-blue-text;
    text-transform: uppercase;
    margin: 0;
  }

  .body {
    position: relative;

    // overflow: hidden;
    min-height: 50vh;
    padding: 20px 5px;
    padding: 3rem 3.8125rem;

    @include media-breakpoint-down(sm) {
      min-height: 0;
      padding: 1.5rem 1.875rem;
    }

    @include media-breakpoint-down(xs) {
      padding: 1.5rem 1rem;

      &.body--xs--no-padding {
        padding: 0;
      }
    }

    &--no-padding {
      padding: 0;
    }
  }

  .top-row {
    top: -2rem;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      top: -1rem;
      margin-bottom: 0.5rem;
    }

    @include media-breakpoint-down(xs) {
      margin: 0 16px 0.5rem;

      > button {
        margin: 2px 0;
      }
    }

    &-titleAndFilter {
      top: 1rem;
      left: 3.25rem;
      margin-bottom: 1rem;

      @include media-breakpoint-down(sm) {
        left: 2rem;
        margin: 0 0 1rem;
      }
    }
  }
}
